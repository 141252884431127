import React from "react";
import { featuresContent } from "../../utils/Data";
import "@fortawesome/fontawesome-free/css/all.min.css";
import logo from "../../assets/image/features.png";  

function Features() {
  return (
    <section className="about-section pt-0 max-[1250px]:pt-0 max-[1024px]:pt-10 max-[950px]:pt-0">
      <div className="container-fluid mx-auto relative flex gap-14 max-[900px]:flex-col max-[900px]:gap-5 max-[900px]:flex-col-reverse">
        
         <div className="w-6/12 flex flex-col max-[900px]:w-full">
          <div>
            <img
              src={logo} 
              alt="Features"
              className="max-[900px]:mx-auto"
            />
          </div>
        </div>

        {/* Text Section */}
        <div className="w-6/12 flex flex-col max-[900px]:w-full">
          <h4 className="text-red-500 mb-2 relative max-[767px]:mb-1">
            {featuresContent.heading.subheading}
          </h4>
          <h2 className="font-bold text-black pb-3">
            {featuresContent.heading.title}
          </h2>
          <span className="title-border mb-3 w-28 h-px bg-red-500"></span>
          
          {/* Description */}
          <div className="ptag font-normal text-gray-600 pb-2">
            {featuresContent.description}
          </div>

          {/* Features List */}
          <ul className="flex flex-col gap-3 mt-2 max-[900px]:gap-2 w-full">
            {featuresContent.featuresList.map((feature, index) => (
              <li key={index} className="flex gap-2">
                <span>
                  <i className="fa-regular fa-circle-dot colorFF3E3A"></i>
                </span>
                <span className="-mt-0.5 font-normal color5a5783 text-lg max-[1200px]:text-base">
                  {feature}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Features;
