import React from 'react';

const TableComponent = () => {
  const tableData = [
    {
      id: 1,
      profileName: 'Orla O, Sullivan',
      profileDesc: 'Octopus is a powerful yet superOctopus is a powerful yet...',
      time: '22 minutes ago',
      payouts: '$5848.68',
      imgSrc: '../images/listing-page-img/user1.svg',
    },
    {
      id: 2,
      profileName: 'John Doe',
      profileDesc: 'John is a reliable professional with experience in...',
      time: '35 minutes ago',
      payouts: '$1234.56',
      imgSrc: '../images/listing-page-img/user2.svg',
    },
    {
      id: 3,
      profileName: 'Jane Smith',
      profileDesc: 'Jane is a creative thinker and problem solver...',
      time: '1 hour ago',
      payouts: '$789.12',
      imgSrc: '../images/listing-page-img/user3.svg',
    },
    {
      id: 4,
      profileName: 'Mike Johnson',
      profileDesc: 'Mike has a wealth of knowledge in finance...',
      time: '2 hours ago',
      payouts: '$3456.78',
      imgSrc: '../images/listing-page-img/user4.svg',
    },
    {
      id: 5,
      profileName: 'Mike Johnson',
      profileDesc: 'Mike has a wealth of knowledge in finance...',
      time: '2 hours ago',
      payouts: '$3456.78',
      imgSrc: '../images/listing-page-img/user4.svg',
    },
    {
      id: 6,
      profileName: 'Mike Johnson',
      profileDesc: 'Mike has a wealth of knowledge in finance...',
      time: '2 hours ago',
      payouts: '$3456.78',
      imgSrc: '../images/listing-page-img/user4.svg',
    },
   
  ];

  return (
    <div className="px-5 py-5">
      <div className="d-table h-connect-table">
        <table className="w-full border bordere7e9f6 rounded-2xl overflow-auto">
          <thead>
            <tr>
              <th className="pt-2 pb-2 bge7e9f6 text-start px-4">
                <div className="flex items-center">Profile</div>
              </th>
              <th className="pt-2 pb-2 bge7e9f6 text-start px-4">
                <div className="flex items-center">Date</div>
              </th>
              <th className="pt-2 pb-2 bge7e9f6 text-start px-4">
                <div className="flex items-center">Payouts</div>
              </th>
              <th className="pt-2 pb-2 bge7e9f6 px-4 text-end ms-auto">
                <div className="flex items-center">Action</div>
              </th>
              <th className="pt-2 pb-2 bge7e9f6 px-4 text-end ms-auto"></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <tr key={item.id}>
                <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color00517C pt-3 pb-3 flex items-center">
                  <span className="inline-block connect-table-checkbox float-left relative">
                    <input
                      type="checkbox"
                      className="w-5 h-5 me-5 relative left-1 top-1 outline outline-offset-2 outline-1 border-0 rounded-sm"
                    />
                  </span>
                  <span className="info float-left flex items-center gap-2">
                    <span className="profile w-10 h-10 rounded-full">
                      <img
                        src={item.imgSrc}
                        className="w-full h-full rounded-full object-cover"
                        alt={`${item.profileName}`}
                      />
                    </span>
                    <span className="profile-info">
                      <span className="font-normal text-base max-[1350px]:text-sm">
                        {item.profileName}
                      </span>
                      <br />
                      <span className="color5a5783 text-sm font-light max-h-96 whitespace-nowrap overflow-hidden text-ellipsis block">
                        {item.profileDesc}
                      </span>
                    </span>
                  </span>
                </td>
                <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color00517C pt-2 pb-2">
                  {item.time}
                </td>
                <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color00517C pt-2 pb-2">
                  {item.payouts}
                </td>
                <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color5a5783 pt-2 pb-2">
                  <a href="/">
                    <i className="fa-solid fa-eye"></i>
                  </a>
                </td>
                <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color5a5783 pt-2 pb-2">
                  <a
                    href="/"
                    className="rounded-full color00517C bge7e9f6 bordere7e9f6 border py-0.5 px-4 font-light inline-block max-[650px]:px-6 max-[650px]:py-1.5 text-base text-sm font-semibold"
                  >
                    Premium
                  </a>
                  <a
                    href="/"
                    className="rounded-full color00517C bge7e9f6 bordere7e9f6 border py-0.5 px-4 font-light inline-block max-[650px]:px-6 max-[650px]:py-1.5 text-base text-sm font-semibold"
                  >
                    Premium
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
