import React, { useState } from 'react';
import { Data } from '../../utils/Data';
const faqData = [
  {
    question: "Do I need a premium LinkedIn account to use SalesMagnet?",
    answer: "While having a LinkedIn Premium account can provide additional benefits, SalesMagnet works seamlessly with both free and premium LinkedIn accounts."
  },
  {
    question: "Is there a trial available for SalesMagnet?",
    answer: "Yes, we offer a free trial for SalesMagnet so you can explore our features and see how it can help automate your LinkedIn outreach."
  },
  {
    question: "How does SalesMagnet differ from other LinkedIn automation tools?",
    answer: "SalesMagnet stands out by offering a user-friendly interface, robust automation features, and full compatibility with LinkedIn Free, Premium, Sales Navigator, and Recruiter Lite accounts."
  }
];


const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faq-section pt-0">
      <div className="container-fluid text-left mx-auto relative flex flex-col">
        <div>
          <h4 className="colorFF3E3A mb-2 relative max-[767px]:mb-1">Faq</h4>
          <h2 className="font-bold color00517C pb-3">{Data[1].title}</h2>
          <span className="title-border mb-3 w-28 h-px bgFF3E3A block"></span>
          <div className="ptag font-normal color5a5783 pb-2">
            {Data[1].desc}
          </div>
        </div>
        <div className="flex mt-6 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap">
          <div className="w-full flex flex-col gap-3">
            {faqData.map((faq, index) => (
              <div key={index} className="bgF7F7F7 rounded-2xl border">
                <h2 id={`accordion-collapse-heading-${index}`}>
                  <button
                    type="button"
                    className="flex items-center justify-between w-full color00517C px-5 max-[650px]:px-3 py-2 rounded-2xl text-gray-500 dark:text-gray-400"
                    onClick={() => toggleAccordion(index)}
                    aria-expanded={openIndex === index}
                    aria-controls={`accordion-collapse-body-${index}`}
                  >
                    <span className="color00517C text-lg max-[650px]:text-base max-[650px]:text-left font-semibold">{faq.question}</span>
                    <svg
                      className={`w-3 h-3 color00517C shrink-0 transition-transform`}

                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      {openIndex === index ? (
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l4 4 4-4" />
                      ) : (
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5l4-4 4 4" />
                      )}
                    </svg>
                  </button>
                </h2>
                <div
                  id={`accordion-collapse-body-${index}`}
                  className={`${openIndex === index ? '' : 'hidden'}`}
                  aria-labelledby={`accordion-collapse-heading-${index}`}
                >
                  <div className="px-5 max-[650px]:px-3 bgF7F7F7 rounded-b-2xl max-[650px]:pb-3">
                    <span className="inline-block font-normal color5a5783 pt-2 pb-4 max-[900px]:pb-1 max-[600px]:pt-1 text-lg pe-20 max-[650px]:pe-2 max-[650px]:text-base">{faq.answer}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;

