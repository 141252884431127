import dashboard from '../../assets/img/dashboard.svg';
import Connect from '../../assets/img/connect.svg'; 
import message from "../../assets/img/message .svg"

export const MAIN_SIDEBAR_DATA = [
  {
    id: 1,
    icon: dashboard,
    path: '/dashboard',
    heading: 'Dashboard',
  },
  {
    id: 2,
    icon: Connect,
    path: '/connect',
    heading: 'Connect',
  },
  {
    id: 3,
    icon: message,
    path: '/message',
    heading: 'Message',
  }

];

