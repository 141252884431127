import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const checkEmail = (email) => {
    const filter = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return filter.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      toast.error('Please enter your email address.');
      return;
    }

    if (!checkEmail(email)) {
      toast.error('Please provide a valid email address');
      return;
    }
    toast.success('Recovery email sent successfully!');
  };

  return (
    <section className="contactus-section pt-0 pb-0 back">
      <div className="container-fluid mx-auto relative flex gap-5 max-[950px]:flex-col justify-center h-screen items-center">
        <div className="flex mt-6 max-[650px]:mt-3 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap w-6/12 max-[950px]:w-full bg-white rounded-2xl p-10 max-[1024px]:p-8 max-[767px]:p-6">
          <div className="contactform w-full">
            <div className="page-title mb-7 max-[1024px]:mb-3">
              <h2 className="font-bold color00517C pb-3">Forgot Password</h2>
              <span className="title-border mb-3 w-28 h-px bgFF3E3A block"></span>
              <div className="ptag font-normal color5a5783 pb-2">
                Meet our team of professional support agents ready to take care of any issue you might face:
              </div>
            </div>

            <form className="flex flex-col gap-4 max-[1024px]:gap-2 max-[650px]:gap-3" onSubmit={handleSubmit}>
              <div className="contactform-row flex gap-5 max-[650px]:gap-3 justify-between w-full max-[650px]:flex-col">
                <div className="inputgroup contact-input w-full">
                  <label className="color00517C text-base max-[650px]:text-sm font-semibold">
                    E-mail Address <span className="text-red-500 text-xl">*</span>
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border rounded-2xl h-11 outline-0 px-5 py-2 color00517C font-normal text-base max-[650px]:h-10"
                    placeholder="E-mail Address"
                  
                  />
                </div>
              </div>

              <div className="contactform-row flex gap-5 justify-between w-full mt-2">
                <div className="inputgroup contact-btn b-btn w-full">
                  <button
                    type="submit"
                    className="transition rounded-full w-full text-white bgFF3E3A borderFF3E3A border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5"
                  >
                    Send recovery email
                  </button>
                </div>
              </div>

              <div className="formdec mt-5 max-[1024px]:mt-2">
                <div className="font-normal color5a5783 text-base">
                  Already have an account?{' '}
                  <Link
                    to="/signin"
                    className="color00517C text-base max-[650px]:text-sm font-semibold"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;

