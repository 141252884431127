import { BASE_URL } from "./axios";

export const registerUserApi = async (userData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
      credentials: "include",
    });

    return await response.json();
  } catch (error) {
    console.error("API call error:", error.message || error);
    throw error;
  }
};

export const loginUserApi = async (userData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/signin`, {  
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("API call error:", error.message || error);
    throw error;
  }
};
