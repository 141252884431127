import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const encryptUserData = (user) => {
   const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(user), 'your-secret-key').toString();
  Cookies.set('user', encryptedData, { expires: 1, secure: true, sameSite: 'Strict' });
};

const decryptUserData = () => {
   const encryptedData = Cookies.get('user');

  if (encryptedData) {
    try {
       const bytes = CryptoJS.AES.decrypt(encryptedData, 'your-secret-key');
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

       return decryptedData ? JSON.parse(decryptedData) : null;
    } catch (error) {
      console.error('Decryption or parsing error:', error);
      return null;
    }
  }

  return null;
};
export { encryptUserData, decryptUserData };
