import { createSlice } from '@reduxjs/toolkit';

// Initial State
export const INITIAL_STATE = {
  users: [],  // Should be an array to match your component logic
  loading: false,
  error: null,
};

const mainSlice = createSlice({
  name: 'certificate',
  initialState: INITIAL_STATE,
  reducers: {
    certificateRequest(state) {
      state.loading = true;
      state.error = null;
    },
    certificateSuccess(state, { payload }) {
      state.loading = false;
      state.users = payload;  
    },
    certificateFailure(state, { payload }) {
      state.loading = false;
      state.error = payload;   
    },
  },
});

export const {
  certificateRequest,
  certificateSuccess,
  certificateFailure,
} = mainSlice.actions;

export default mainSlice.reducer;
