import { all } from 'redux-saga/effects';
 
import { watchCertificateSagas } from './sagas/CertificateSaga';
import {watchSignupSagas , watchLoginSagas} from './sagas/signupSaga'
 
export default function* rootSaga() {
  yield all([
    watchCertificateSagas(),
    watchSignupSagas(),
    watchLoginSagas()
   ]);
}
