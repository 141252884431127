import React, { useState } from 'react';

function PersonalizePremium() {
  const [inputValue, setInputValue] = useState('');

  // Static data for the table
  const staticData = [
    {
      name: 'Orla O, Sullivan',
      description: 'Octopus is a powerful yet super...',
      time: '22 minutes ago',
      status: 'Processed',
    },
    {
      name: 'John Doe',
      description: 'This is a static description for John Doe...',
      time: '45 minutes ago',
      status: 'Pending',
    },
    {
      name: 'Jane Smith',
      description: 'Static data for Jane Smith...',   
      time: '1 hour ago',
      status: 'Processed',
    },
  ];

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleLaunchClick = (e) => {
    e.preventDefault();
    console.log("Launched with value:", inputValue);
  };

  return (
    <>
      <div className="d-global-t">
        <span className="color00517C text-base max-[1350px]:text-sm font-semibold px-5 py-4 block border bordere7e9f6 border-t-0 border-e-0 border-s-0 max-[650px]:py-2">
          How many users to connect?
        </span>
      </div>
      <div className="px-5 py-4">
        <div className="flex justify-between gap-5 max-[500px]:flex-wrap max-[500px]:gap-2">
          <form className="flex flex-col gap-5 max-[650px]:gap-3 w-full">
            <div className="inputgroup relative flex">
              <input
                type="text"
                className="w-full border rounded-2xl outline-0 py-1 px-5 color00517C font-normal text-base max-[1350px]:text-sm"
                value={inputValue}
                onChange={handleInputChange}
                required
              />
              <button
                className="transition absolute right-0 rounded-full text-white bgFF3E3A borderFF3E3A border py-1 px-5 font-light inline-block text-base max-[1350px]:text-sm"
                onClick={handleLaunchClick}
              >
                Launch
              </button>
            </div>
          </form>
          <ul className="flex gap-1 items-center d-global-btn max-[500px]:ms-auto">
            <li>
              <button className="rounded-full text-white bg00517C border00517C border py-1 px-5 font-light inline-block text-base max-[1350px]:text-sm">
                Import
              </button>
            </li>
            <li>
              <button className="rounded-full text-white bg00517C border00517C border py-1 px-5 font-light inline-block text-base max-[1350px]:text-sm">
                Export
              </button>
            </li>
          </ul>
        </div>
        <hr className="mt-5 mb-5" />
        <div className="d-table h-connect-table">
          <table className="w-full border bordere7e9f6 rounded-2xl overflow-auto">
            <thead>
              <tr>
                <th className="pt-2 pb-2 bge7e9f6 text-start px-4">
                  <div className="flex items-center">
                    <input type="checkbox" className="w-5 h-5 me-3" />
                    <a
                      href="/"
                      className="rounded-full text-white bg00517C border00517C border py-0.5 px-4 font-light text-sm"
                    >
                      Premium
                    </a>
                    <div className="inputgroup relative flex">
                      <input
                        type="text"
                        className="w-full border rounded-2xl outline-0 py-1 px-5 color00517C font-normal text-base max-[1350px]:text-sm"
                        required
                      />
                      <button
                        className="rounded-full text-white bg00517C border00517C border py-0.5 px-4 font-light inline-block text-sm absolute right-1 top-1"
                      >
                        Cut
                      </button>
                    </div>
                  </div>
                </th>
                <th className="pt-2 pb-2 bge7e9f6 text-start px-4">
                  <a
                    href="/"
                    className="rounded-full text-white bg00517C border00517C border py-0.5 px-4 font-light text-sm"
                  >
                    Forward
                  </a>
                </th>
                <th className="pt-2 pb-2 bge7e9f6 px-4 text-end">
                  <div className="flex gap-1 items-center">
                    <select>
                      <option>Oldest First</option>
                      <option>Newest First1</option>
                      <option>Newest First2</option>
                    </select>
                    <span className="icon w-4 h-4 block">
                    <i className="fa-solid fa-trash-can text-blue-950"></i>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {staticData.map((data, index) => (
                <tr key={index}>
                  <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color00517C pt-2 pb-2">
                    <span className="inline-block connect-table-checkbox float-left relative top-1">
                      <input type="checkbox" className="w-5 h-5 me-3 relative left-1 top-1 outline outline-offset-2 outline-1 border-0 rounded-sm" />
                    </span>
                    <span className="info float-left">
                      <span className="font-normal text-base max-[1350px]:text-sm">{data.name}</span>
                      <br />
                      <span className="color5a5783 text-sm font-light max-h-96 whitespace-nowrap overflow-hidden text-ellipsis block">
                        {data.description}
                      </span>
                    </span>
                  </td>
                  <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color00517C pt-2 pb-2">
                    {data.time}
                  </td>
                  <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color5a5783 pt-2 pb-2 text-end">
                    {data.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </>
  );
}

export default PersonalizePremium;
