import React, { useState } from "react";
import PricingPlanCards from "./PricingPlanCards";
import { Data } from "../../utils/Data";
import { useLocation } from "react-router-dom";

const pricingPlans = {
  Monthly: [
    {
      name: "Starter",
      price: 10,
      currency: "USD",
      billingCycle: "month",
      teamMembers: "2 team members",
      cloudStorage: "20GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#FFF2FB]",
      barColor: "bg-[#fbe0f3]",
    },
    {
      name: "Starter",
      price: 20,
      currency: "USD",
      billingCycle: "month",
      teamMembers: "2 team members",
      cloudStorage: "20GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#f2f3fa]",
      barColor: "bg-[#e2e6ff]",
    },
    {
      name: "Starter",
      price: 30,
      currency: "USD",
      billingCycle: "month",
      teamMembers: "2 team members",
      cloudStorage: "20GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#FEECEE]",
      barColor: "bg-[#fde4e7]",
    },
  ],
  Annually: [
    {
      name: "Starter",
      price: 40,
      currency: "USD",
      billingCycle: "year",
      teamMembers: "5 team members",
      cloudStorage: "100GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#FFF2FB]",
      barColor: "bg-[#fbe0f3]",
    },
    {
      name: "Starter",
      price: 50,
      currency: "USD",
      billingCycle: "year",
      teamMembers: "5 team members",
      cloudStorage: "100GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#f2f3fa]",
      barColor: "bg-[#e2e6ff]",
    },
    {
      name: "Starter",
      price: 60,
      currency: "USD",
      billingCycle: "year",
      teamMembers: "5 team members",
      cloudStorage: "100GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#FEECEE]",
      barColor: "bg-[#fde4e7]",
    },
  ],
  Agency: [
    {
      name: "Starter",
      price: 70,
      currency: "USD",
      billingCycle: "month",
      teamMembers: "10 team members",
      cloudStorage: "200GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#FFF2FB]",
      barColor: "bg-[#fbe0f3]",
    },
    {
      name: "Starter",
      price: 80,
      currency: "USD",
      billingCycle: "month",
      teamMembers: "10 team members",
      cloudStorage: "200GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#f2f3fa]",
      barColor: "bg-[#e2e6ff]",
    },
    {
      name: "Starter",
      price: 90,
      currency: "USD",
      billingCycle: "month",
      teamMembers: "10 team members",
      cloudStorage: "200GB Cloud storage",
      features: [
        "Integration help",
        "Sketch Files",
        "API Access",
        "Complete documentation",
        "24×7 phone & email support",
      ],
      colorClass: "bg-white",
      headerColor: "bg-[#FEECEE]",
      barColor: "bg-[#fde4e7]",
    },
  ],
};

function PricingPlan() {
  const [activeTab, setActiveTab] = useState("Monthly");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const location = useLocation();

  const noAbsolutePaths = ["/PricingPlans", "/contact", "/videosection"];
  const isNoAbsolutePath = noAbsolutePaths.includes(location.pathname);

  return (
    <section
      className={`pricing-section ${isNoAbsolutePath ? "pt-10" : "pt-0"} flex`}
    >
      <div className="mx-auto relative flex flex-col w-[80%]">
        <div>
          <h4 className="mb-2 text-left text-[#FF3E3A] relative max-[767px]:mb-1">
            Our Pricing
          </h4>
          <h2 className="font-bold text-left text-black text-[30px] pb-3">
            {Data[2].title}
          </h2>
          <span className="title-border mb-3 w-28 h-px bg-[#FF3E3A] block"></span>
          <div className="font-normal text-left text-[#6b688d] pb-2">
            {Data[2].desc}
          </div>
        </div>
        <div className="flex mt-6 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap">
          <div className="w-full">
            <div className="relative">
              <ul className="relative font-semibold flex flex-wrap gap-1">
                {["Monthly", "Annually", "Agency"].map((tab) => (
                  <li key={tab} className="z-30 pricing-list">
                    <button
                      className={`cursor-pointer font-semibold text-base pt-2 pb-2 ps-5 inline-block rounded-t-2xl pe-5 ${
                        activeTab === tab ? "bg-[#f2f3fa]" : ""
                      }`}
                      role="tab"
                      aria-selected={activeTab === tab}
                      onClick={() => handleTabChange(tab)}
                    >
                      <span>{tab}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="p-8 max-[650px]:p-4 bg-[#f2f3fa] rounded-2xl rounded-tl-none pricing-b-cover">
              <div className="flex gap-6 max-[650px]:gap-4 justify-between opacity-100 max-[950px]:flex-wrap max-[950px]:justify-center">
                {pricingPlans[activeTab].map((plan, index) => (
                  <PricingPlanCards key={index} plan={plan} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingPlan;
