import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
 
ChartJS.register(
  CategoryScale,
  LineController,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartComponent = () => {
  const data = {
    labels: ['Jan 01', 'Jan 02', 'Jan 03', 'Jan 04', 'Jan 05', 'Jan 06', 'Jan 07', 'Jan 08', 'Jan 09', 'Jan 10', 'Jan 11', 'Jan 12'],
    datasets: [
      {
        label: 'Website Blog',
        data: [400, 300, 500, 500, 600, 200, 100, 400, 300, 600, 300, 200],
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Social Media', 
        data: [400, 300, 500, 700, 600, 200, 100, 400, 300, 800, 300, 200],
        type: 'line',
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.4, 
        pointRadius: 5,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        fill: false,
      },
    ],
  };

  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true, 
        position: 'bottom', 
        labels: {
          usePointStyle: true, 
          pointStyle: 'circle', 
          font: {
            size: 10, 
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className='aa'>
      <div className="d-global-t"><span className="color00517C text-base max-[1350px]:text-sm font-semibold px-5 py-4 block border bordere7e9f6 border-t-0 border-e-0 border-s-0">Personalize:</span></div>
      <div className='px-5 py-4'>
        <Bar data={data} options={options}  className='w-full'/>
      </div>
    </div>
  );
};

export default ChartComponent;

