import React from 'react';

const ContactForm = ({ formData, handleChange, handleSubmit }) => {
  return (
    <form className="flex flex-col gap-5 max-[650px]:gap-3" onSubmit={handleSubmit}>
      <div className="contactform-row flex gap-5 max-[650px]:gap-3 justify-between w-full max-[650px]:flex-col">
        <div className="inputgroup text-left contact-input w-6/12 max-[650px]:w-full">
          <label className="color00517C text-base max-[650px]:text-sm font-semibold">First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full border rounded-2xl h-11 outline-0 px-5 py-2 color00517C font-normal text-base max-[650px]:h-10"
            required
          />
        </div>
        <div className="inputgroup text-left contact-input w-6/12 max-[650px]:w-full">
          <label className="color00517C text-base max-[650px]:text-sm font-semibold">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full border rounded-2xl h-11 outline-0 px-5 py-2 color00517C font-normal text-base max-[650px]:h-10"
            required
          />
        </div>
      </div>
      <div className="contactform-row text-left flex gap-5 max-[650px]:gap-3 justify-between w-full max-[650px]:flex-col">
        <div className="inputgroup contact-input w-6/12 max-[650px]:w-full">
          <label className="color00517C text-base max-[650px]:text-sm font-semibold">Your Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full border rounded-2xl h-11 outline-0 px-5 py-2 color00517C font-normal text-base max-[650px]:h-10"
            required
          />
        </div>
        <div className="inputgroup text-left contact-input w-6/12 max-[650px]:w-full">
          <label className="color00517C text-base max-[650px]:text-sm font-semibold">Your Phone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full border rounded-2xl h-11 outline-0 px-5 py-2 color00517C font-normal text-base max-[650px]:h-10"
            required
          />
        </div>
      </div>
      <div className="contactform-row text-left flex gap-5 justify-between w-full">
        <div className="inputgroup contact-textarea w-full">
          <label className="color00517C text-base max-[650px]:text-sm font-semibold">Your Message (optional)</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full border rounded-2xl outline-0 px-5 py-2 color00517C font-normal text-base h-28 max-[650px]:h-24"
          ></textarea>
        </div>
      </div>
      <div className="contactform-row text-left flex gap-5 justify-between w-full">
        <div className="inputgroup contact-btn b-btn">
          <button
            type="submit"
            className="transition rounded-full text-white bgFF3E3A borderFF3E3A border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
