import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/RootReducer'; // Import combined reducer
import rootSaga from '../middleware/RootSaga'; // Import combined saga

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer, // Use the combined reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

// Run the saga middleware
sagaMiddleware.run(rootSaga);

export default store;
