import React, { useRef, useState } from 'react';

const VideoPlayer = ({ video }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <div className="w-6/12 flex flex-col max-[900px]:w-full video-col-box relative overlay-before max-[950px]:w-full">
      <div
        className={`videocol banner-video border flex justify-center items-center rounded-2xl overflow-hidden relative cursor-pointer ${isPlaying ? 'bg-transparent' : 'bg-blue-500'}`} // Change background color based on playing state
        onClick={handleVideoClick}
      >
        {!isPlaying && (
          <span className="playbtn absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 bg3B88F5 text-white flex justify-center items-center rounded-full">
            <i className="fa-solid fa-play"></i>
          </span>
        )}
        <video
          className="w-full h-full object-cover"
          poster={video.poster}
          ref={videoRef}
          onPause={() => setIsPlaying(false)}
          onPlay={() => setIsPlaying(true)}
        >
          <source src={video.source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoPlayer;
