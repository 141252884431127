import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const Personalize = () => {
  const tableData = [
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
    { name: 'Orla O, Sullivan', time: '22 minutes ago', status: 'Processed' },
  ]
  return (
    <div className="bg-white rounded-2xl mb-4 max-[900px]:mb-0 max-[900px]:w-2/4 max-[750px]:w-full">
      <div className="d-global-t">
        <span className="color00517C text-base max-[1350px]:text-sm font-semibold px-5 py-4 block border bordere7e9f6 border-t-0 border-e-0 border-s-0">
          Personalize:
        </span>
      </div>
      <div className="px-5 py-4">
        <div className="d-global-btn">
          <ul className="flex gap-3 items-center max-[1350px]:flex-wrap max-[1350px]:gap-1">
            <li>
              <Link to="#" className="font-normal color5a5783 inline-block text-base max-[1350px]:text-sm max-[450px]:text-xs">
                Firstname
              </Link>
            </li>
            <li className="font-normal color5a5783 inline-block text-xs max-[450px]:text-xs">
              <FontAwesomeIcon icon={faAngleDoubleRight} className="text-xs" />
            </li>
            <li>
              <Link to="#" className="font-normal color5a5783 inline-block text-base max-[1350px]:text-sm max-[450px]:text-xs">
                Lastname
              </Link>
            </li>
            <li className="font-normal color5a5783 inline-block text-xs max-[450px]:text-xs">
              <FontAwesomeIcon icon={faAngleDoubleRight} className="text-xs" />
            </li>
            <li>
              <Link to="#" className="font-normal color5a5783 inline-block text-base max-[1350px]:text-sm max-[450px]:text-xs">
                Position
              </Link>
            </li>
            <li className="font-normal color5a5783 inline-block text-xs max-[450px]:text-xs">
              <FontAwesomeIcon icon={faAngleDoubleRight} className="text-xs" />
            </li>
            <li>
              <Link to="#" className="font-normal color5a5783 inline-block text-base max-[1350px]:text-sm max-[450px]:text-xs">
                Company
              </Link>
            </li>
          </ul>
        </div>

        <div className="d-table personalize-table mt-3">

          <table className="w-full border bordere7e9f6 rounded-2xl top-baar-table">
            <thead>
              <tr>
                <th className="pt-2 pb-2 bge7e9f6 text-start px-4">
                  <span className="inline-block font-normal me-4 color00517C text-base max-[1350px]:text-sm">1908</span>
                  <a href="/" className="rounded-full text-white bg00517C border00517C border py-0.5 px-4 font-light inline-block text-base max-[1350px]:text-sm text-sm">Forward</a>
                </th>
                <th className="pt-2 pb-2 bge7e9f6 text-start px-1">
                  <a href="/" className="rounded-full text-white bg00517C border00517C border py-0.5 px-4 font-light inline-block text-base max-[1350px]:text-sm text-sm">Extract to CSV</a>
                </th>
                <th className="pt-2 pb-2 bge7e9f6 px-4 text-end">
                  <span className="icon w-4 h-4 block ms-auto">
                    <i className="fa-solid fa-trash-can"></i>
                  </span>
                </th>
              </tr>
            </thead>
          </table>


          <div className="max-h-48">
            <table className="w-full border bordere7e9f6 rounded-2xl">
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color00517C pt-2 pb-2">
                      {row.name}
                    </td>
                    <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color00517C pt-2 pb-2">
                      {row.time}
                    </td>
                    <td className="font-light text-base max-[1350px]:text-sm px-4 border border-t-0 border-s-0 border-e-0 bordere7e9f6 color5a5783 pt-2 pb-2 text-end">
                      {row.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


  );
};

export default Personalize;
