import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

function PricingPlanCards({ plan }) {
    return (
        <div className={`w-full p-6 ${plan.colorClass} rounded-2xl relative max-[950px]:p-4 max-[950px]:w-6/12`}>
            <span className={`${plan.barColor} flex h-2 rounded-full absolute top-0 w-10/12 left-1/2 -translate-x-1/2 -translate-y-1/2`}></span>
            <div className={`p-header ${plan.headerColor} rounded-2xl text-left p-5 max-[950px]:p-3`}>
                <span className="text-[#3B88F5] text-base font-semibold">{plan.name}</span>
                <div className="flex items-baseline">
                    <span className="text-3xl color00517C font-semibold">$</span>
                    <span className="text-5xl tracking-tight font-bold max-[650px]:text-4xl">{plan.price}</span>
                    <span className="ms-1 text-base text-[#6b688d] font-semibold">/ {plan.billingCycle}</span>
                </div>
            </div>
            <ul className="space-y-2.5 my-5 max-[650px]:space-y-2 max-[650px]:my-4">
                <li className="flex items-center">
                <FontAwesomeIcon icon={faCircleCheck} />
                    <span className="text-base semibold leading-tight ms-3">{plan.teamMembers}</span>
                </li>
                <li className="flex">
                <FontAwesomeIcon icon={faCircleCheck} />
                    <span className="text-base semibold leading-tight ms-3">{plan.cloudStorage}</span>
                </li>
                <li className="flex">
                <FontAwesomeIcon icon={faCircleCheck} />
                    <span className="text-base semibold leading-tight ms-3">{plan.features[0]}</span>
                </li>
                {plan.features.slice(1).map((feature, index) => (
                    <li className="flex color808291 decoration-gray-500" key={index}>
                         <FontAwesomeIcon icon={faCircleCheck} />
                        <span className="text-base line-through semibold leading-tight color5a5783 ms-3">{feature}</span>
                    </li>
                ))}
            </ul>
            <div className="b-btn mr-[180px]">
                <a href="/" className="rounded-full text-white bg-[#FF3E3A] border-[#FF3E3A] border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5">Free Trial</a>
            </div>
        </div>
    );
}

export default PricingPlanCards;
