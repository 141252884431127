import Axios from 'axios';
// const { CancelToken } = Axios;
// let cancelTokenSource = CancelToken.source();

//  Function to cancel all ongoing requests
// export const cancelAllRequests = () => {
//   cancelTokenSource.cancel('Session timed out.');
//   cancelTokenSource = CancelToken.source();
// };

export const BASE_URL = process.env.REACT_APP_BASE_URL ||  'https://api.salesmagnet.io';


const axios = Axios.create({
  baseURL: BASE_URL,
});

axios.interceptors.request.use(
  (req) => {
    localStorage.setItem('api_time', Math.floor(new Date().getTime() / (1000 * 60)));

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {


    return response;
  },
  function (error) {


    return Promise.reject(error);
  }
);

export default axios;