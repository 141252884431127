import React from 'react';
import videoimg from '../../assets/img/banner-vi-img.jpg';
import VideoPlayer from './VideoPlayer';
import { useLocation } from "react-router-dom";

const videosData = [
  {
    title: 'Getting Started with SalesMagnet',
    description: 'In this video, we’ll introduce you to SalesMagnet, showcasing the key features and guiding you through how to make the most of our LinkedIn automation tools for growing your business.',
    poster: videoimg,
    source: '/video/salesmagnet-intro.mp4',
  },
  {
    title: 'SalesMagnet Features Overview',
    description: 'Discover the powerful features of SalesMagnet that can enhance your LinkedIn outreach. This video provides a detailed overview of the tools designed to streamline your lead generation efforts.',
    poster: videoimg,
    source: '/video/salesmagnet-features.mp4',
  },
  {
    title: 'Maximizing LinkedIn Prospecting with SalesMagnet',
    description: 'Learn how to leverage SalesMagnet to boost your LinkedIn prospecting strategy. This video walks through key tips and best practices for using automation to drive sales success.',
    poster: videoimg,
    source: '/video/salesmagnet-prospecting.mp4',
  },
];

const VideoSection = () => {
  const location = useLocation(); 

   const noAbsolutePaths = ["/PricingPlans", "/contact", "/videosection"];
  const isNoAbsolutePath = noAbsolutePaths.includes(location.pathname);

  return (
    <section className={`video-t-section ${isNoAbsolutePath ? "pt-10" : "pt-0"} overflow-hidden`}>
      <div className="container-fluid mx-auto relative flex flex-col">
        <Header /> {/* Render Header here */}
        {videosData.map((video, index) => (
          <VideoCard key={index} video={video} index={index} />
        ))}
      </div>
    </section>
  );
};

const Header = () => (
  <div>
    <h4 className="color5a5783 mb-2 colorFF3E3A relative max-[767px]:mb-1">Tutorial</h4>
    <h2 className="font-bold color00517C pb-3">SalesMagnet Tutorial</h2>
    <span className="title-border mb-3 w-28 h-px bgFF3E3A block"></span>
    <div className="ptag font-normal color5a5783 pb-2">
      Octopus is a powerful yet super simple autopilot for LinkedIn that automates your work and has a wide range of awesome features.
    </div>
  </div>
);

const VideoCard = ({ video, index }) => {
  const isEvenIndex = index % 2 === 0; 
  return (
    <div className={`flex mt-14 ${isEvenIndex ? '' : 'flex-col-reverse'}`}>
      <div className="info-article-video flex items-center max-[950px]:flex-col max-[950px]:mb-10 max-[850px]:mb-0">
        {isEvenIndex ? (
          <>
            <VideoContent video={video} />
            <VideoPlayer video={video} />
          </>
        ) : (
          <>
            <VideoPlayer video={video} />
            <div className="ml-[50px]" /> 
            <VideoContent video={video} />
          </>
        )}
      </div>
    </div>
  );
};

const VideoContent = ({ video }) => (
  <div className="w-6/12 flex flex-col max-[900px]:w-full video-col-cont relative overlay-before pe-14 max-[950px]:w-full max-[950px]:pe-0 max-[950px]:pb-5">
    <h2 className="font-bold color00517C pb-3">{video.title}</h2>
    <span className="title-border mb-3 w-28 h-px bgFF3E3A"></span>
    <div className="ptag font-normal color5a5783 pb-2">{video.description}</div>
  </div>
);

export default VideoSection;
