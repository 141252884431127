import React from "react";
import { ourServicesContent } from "../../utils/Data";

function OurServices() {
  return (
    <section className=" features-section Services-sec pt-0">
      <div className="container-fluid mx-auto relative flex flex-col">
        <div>
          <h4 className="color5a5783mb-2 colorFF3E3A relative max-[767px]:mb-1">
            {ourServicesContent.heading.subheading}
          </h4>
          <h2 className="font-bold color00517C pb-3">
            {ourServicesContent.heading.title}
          </h2>
          <span className="title-border mb-3 w-28 h-px bgFF3E3A block"></span>
          <div className="ptag font-normal color5a5783 pb-2">
            {ourServicesContent.heading.description}
          </div>
        </div>

        <div className="flex mt-6 gap-5 max-[1024px]:mt-5 max-[950px]:flex-wrap relative overlay-before px-5 max-[950px]:px-0">
          {ourServicesContent.servicesList.map((service, index) => (
            <article
              key={index}
              className={`info-article ${service.bgColor} rounded-2xl p-8 w-1/3 max-[1200px]:p-8 max-[650px]:p-5 max-[950px]:w-full`}
            >
              <div className="infoatitle">
                <h4 className="color00517C font-bold max-[950px]:text-lg">
                  {service.title}
                </h4>
              </div>
              <div className="infoaicon pt-2 pb-3 max-[950px]:pb-2 max-[950px]:pt-1">
                <i className={`transition ${service.iconClass}`}></i>
              </div>
              <div className="infoadec text-base font-normal color5a5783">
                {service.description}
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
}

export default OurServices;
