import { put, call, takeLatest } from 'redux-saga/effects';
import { certificateApi } from '../../../api/dummy';
import {
  certificateRequest,
  certificateSuccess,
  certificateFailure,
} from '../../reducers/ducks/CertificateDuck';

export function* certificateSaga() {
  try {
    const users = yield call(certificateApi);  // Fetch the user data
    yield put(certificateSuccess(users));  // Dispatch success action
  } catch (error) {
    yield put(certificateFailure(error.message));  // Dispatch failure action
  }
}

 export function* watchCertificateSagas() {
  yield takeLatest(certificateRequest.type, certificateSaga);
}
