  import React from 'react';

  const Home = ({ posterSrc, title, subtitle, description, buttonText, buttonLink }) => {
    return (
      <section className="h-screen pt-32 md:pt-24 flex justify-center items-center banner-section relative overflow-hidden bg-red">
        <span className="overlay-before overlay-after banner-bg absolute top-0 right-0 w-full h-full">
          <video className="w-full h-full object-cover" poster={posterSrc} id="sample_video_one" autoPlay muted loop >
            <source src="video/banner-video3.mp4" type="video/mp4"/>
            

          </video>
          
        </span>
        <div className="container-fluid mx-auto relative">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full md:w-8/12 flex flex-col justify-center">
              <h4 className="mb-2 text-white relative">{title}</h4>
              <h1 className="font-bold text-white">{subtitle}</h1>
              <p className="font-normal text-white pt-2 pb-4 md:font-light">
                {description}
              </p>
              <div className="b-btn">
                <a href={buttonLink} className="b-btn rounded-full text-white bg00517C border1f0757 border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5">
                  {buttonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      
      </section>
        
    );
  };

  export default Home;
