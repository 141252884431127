import React, { useState, useEffect } from "react";
import { menuItems } from "../../utils/Data";
import logo from "../../assets/img/logo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logow from "../../assets/img/logow.svg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 70);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };
  const location = useLocation();

  const noAbsolutePaths = ["/PricingPlans", "/contact", "/videosection"];

  // Check if the current path is in the array
  const isNoAbsolutePath = noAbsolutePaths.includes(location.pathname);
  return (
    <header
      className={`${isNoAbsolutePath ? "" : "absolute inn"
        }  w-full z-20 py-5 max-[1024px]:py-3 header-bg transition overlay-before  ${isScrolled ? "fixed-header" : ""
        }`}
    >
      <div className="container-fluid mx-auto relative">
        <nav>
          <div className="headertop mx-auto flex justify-between items-center">
            <Link to="/" className="logo">
              <img
                src={logo}
                className="re-logo-b-o w-52 max-[500px]:w-44 transition"
                alt="Logo"
              />
              <img
                src={logow}
                className="re-logo-b-w w-52 max-[500px]:w-44 hidden transition"
                alt="Logo White"
              />
            </Link>

            {/* Toggle Button */}
            <button
              onClick={toggleMenu}
              className="max-[1023px]:ms-auto inline-flex items-center p-2 justify-center text-sm text-white lg:hidden focus:outline-none"
              aria-controls="navbar-default"
              aria-expanded={isMenuOpen}
            >
              <div
                className={`toggle relative w-11 h-10 max-[1023px]:h-8 bg-red-500 rounded-xl flex items-center justify-center cursor-pointer overflow-hidden ${
                  isMenuOpen ? "open" : ""
                }`}
              >
                {/* Hamburger/Cross icon */}
                <span
                  className={`bg-white absolute transition w-5 h-0.5 left-1.5 rounded-full ${isMenuOpen ? "rotate-45 translate-y-2" : "translate-y-0"
                    }`}
                ></span>
                <span
                  className={`bg-white absolute transition  w-5 h-0.5 left-1.5 rounded-full ${isMenuOpen ? "-rotate-45 -translate-y-2" : "translate-y-0"
                    }`}
                ></span>
                <span
                  className={`bg-white absolute transition   w-8 h-0.5 left-1.5 rounded-full `}
                ></span>
              </div>
            </button>

            {/* Menu */}
            <div
              className={`cros menu homenav w-full lg:block lg:w-auto max-[1023px]:absolute max-[1023px]:w-full max-[1023px]:top-14 max-[1023px]:left-0 max-[767px]:bg-white max-[767px]:border max-[767px]:mt-3 ${
                isMenuOpen ? "block" : "hidden"
              }`}
              id="navbar-default"
            >
              <ul className="font-medium flex flex-col items-center lg:p-0 mt-4 lg:flex-row space-x-8 lg:mt-0 max-[1024px]:space-x-4 max-[767px]:space-x-0 max-[767px]:mt-0">
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.href}
                      onClick={handleMenuItemClick}
                      className="source-sans py-6 font-semibold text-base color00517C relative max-[1023px]:bg-white"
                    >
                      {item.label}
                      <span className="overlay-before"></span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Static Sign-in and Sign-up Buttons */}
            <div className="signin-signup menu-r-btn">
              <ul className="flex">
                <li className="signin-btn">
                  <Link
                    to="/signin"
                    className="rounded-full text-white bgFF3E3A font-medium borderFF3E3A border text-base rounded-tr-none	rounded-br-none	 py-1 px-5 max-[1024px]:px-3 max-[1024px]:py-1 max-[500px]:py-1.0 inline-block relative"
                  >
                    <i className="max-[500px]:text-sm fa-solid fa-user  max-[500px]:flex hidden mobile-icon"></i>{" "}
                    <span className="max-[500px]:hidden">Sign In</span>
                  </Link>
                </li>
                <li className="signup-btn ms-1">
                  <Link
                    to="/signup"
                    className="rounded-full text-white bgFF3E3A font-medium borderFF3E3A border text-base	py-1 rounded-tl-none rounded-bl-none px-5 max-[1024px]:px-3 max-[1024px]:py-1 max-[500px]:py-1.0 inline-block relative"
                  >
                    <i className="max-[500px]:text-sm fa-solid fa-user-plus hidden max-[500px]:flex mobile-icon"></i>{" "}
                    <span className="max-[500px]:hidden">Sign Up</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
