import React from "react";
import { aboutContent } from "../../utils/Data";
import logo from "../../assets/image/about3.png"; 

function About() {
  return (
    <section className="about-section">
      <div className="container-fluid mx-auto relative flex gap-14 max-[900px]:flex-col max-[900px]:gap-5">
        
        {/* Text Section */}
        <div className="w-8/12 flex flex-col max-[900px]:w-full">
          <h4 className="text-red-500 mb-2 relative max-[767px]:mb-1">
            {aboutContent.heading.subheading}
          </h4>
          <h2 className="font-bold text-black pb-3">
            {aboutContent.heading.title}
          </h2>
          <span className="title-border mb-3 w-28 h-px bg-red-500"></span>
          
          {/* Paragraphs */}
          {aboutContent.paragraphs.map((paragraph, index) => (
            <div key={index} className="ptag font-normal text-gray-600 pb-2">
              {paragraph}
            </div>
          ))}

          {/* Read More Button */}
          <div className="b-btn mt-5">
            <a
              href="/"
              className="rounded-full text-white bg-red-500 border-red-500 border py-1.5 px-8 font-light inline-block max-[1024px]:px-6 max-[1024px]:py-1 max-[650px]:px-6 max-[650px]:py-1.5"
            >
              Read More
            </a>
          </div>
        </div>

        {/* Image Section */}
        <div className="w-4/12 flex flex-col max-[900px]:w-full">
          <div>
            <img
              src={logo} // Dynamically using image from data.js
              alt="Company Illustration"
              className="max-[900px]:mx-auto"
            />
          </div>
        </div>

      </div>
    </section>
  );
}

export default About;
