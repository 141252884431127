import SignIn from "../components/signIn/signIn";
import SignUp from "../components/SignUpForm/SignUp"
import ForgotPassword from "../components/Forgot-Password/forgot"
import Dashboard from "../components/Dashboard/Dashboard";


const commonRoutes = [
  {
    path: "/signin",
    component: SignIn,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
  },
  {
    path:'/dashboard',
    component:Dashboard
  }
  // {
  //   path: "/signup",
  //   component: SignUp,
  // },
  // {
  //   path: "/forgotpassword",
  //   component: ForgotPassword,
  // },
];

export { commonRoutes };
