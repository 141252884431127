import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import NavBar from "./components/Navbar/navbar";
import Footer from "./components/Footer/Footer";
import Home from "./components/HomePage/Home";
import About from "./components/About/About";
import Features from "./components/Features/Features";
import OurServices from "./components/Services/OurServices";
import PricingPlan from "./components/Pricing-Plans/PricingPlan";
import ContactUs from "./components/ContactUs-Section/ContactUs";
import Faq from "./components/FAQ-Section/Faq";
import { menuItems, buttons, bannerContent } from "./utils/Data";
import { commonRoutes } from "./routes/index";
import VideoSection from "./components/VideoTutorialTab/VideoSection";
import { ToastContainer } from "react-toastify";
 
import Input from "./components/VerifyAccess/VerifyAccess";


function App() {
  const location = useLocation();

  const isVerified = sessionStorage.getItem("isVerified") === "true";

  if (!isVerified && location.pathname !== "/verify") {
    return <Navigate to="/verify" replace />;
  }

  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={1500} />

    
      {!location.pathname.includes("verify") && (
        <>
          {location.pathname !== "/signin" &&
            location.pathname !== "/signup" &&
            location.pathname !== "/forgotpassword" &&
            location.pathname !== "/dashboard" && (
              <NavBar menuItems={menuItems} buttons={buttons} />
            )}
        </>
      )}

      <Routes>

        <Route path="/" element={isVerified ? <Navigate to="/Home" replace /> : <Navigate to="/verify" replace />} />

        <Route path="/verify" element={<Input />} />


        {isVerified && (
          <>
            <Route
              path="/Home"
              element={
                <>
                  <Home
                    title={bannerContent.title}
                    subtitle={bannerContent.subtitle}
                    description={bannerContent.description}
                    buttonText={bannerContent.buttonText}
                    buttonLink={bannerContent.buttonLink}
                  />
                  <About />
                  <Features />
                  <OurServices />
                  <PricingPlan />
                  <Faq />
                  <ContactUs />
                </>
              }
            />
            {commonRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={<route.component />} />
            ))}
            <Route
              path="/PricingPlans"
              element={
                <>
                  <PricingPlan />
                  <ContactUs />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <ContactUs />
                  <PricingPlan />
                </>
              }
            />
            <Route
              path="/videosection"
              element={
                <>
                  <VideoSection />
                  <ContactUs />
                </>
              }
            />
          </>
        )}
      </Routes>

      {!location.pathname.includes("verify") &&
        location.pathname !== "/signin" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/forgotpassword" &&
        location.pathname !== "/dashboard" && <Footer />}
    </div>
  );
}

export default App;
