import React, { useState } from 'react';
import PersonalizePremium from '../PersonalizePremium/PersonalizePremium';
import Personalize from '../Personalize/Personalize';
import Chart from '../Personalize-Chart/Chart';
import Sidebar from "../Sidebar/Sidebar";
import Navbar from '../PersonalizePremium/Navbar';
import RevenueHistory from '../RevenueHistory/RevenueHistory'; 

function Dashboard() {
  const [currentComponent, setCurrentComponent] = useState('default'); 

  const handleSelect = (heading) => {
    if (heading === 'Connect') {
      setCurrentComponent('RevenueHistory'); 
    } else {
      setCurrentComponent('default');
    }
  };

  const renderCurrentComponent = () => {
    switch (currentComponent) {
      case 'RevenueHistory':
        return <RevenueHistory />;
      case 'default':
      default:
        return (
          <>
            <div className="flex justify-between gap-5 max-[900px]:flex-wrap max-[650px]:gap-3">
              <div className="w-5/12 max-[900px]:w-full max-[900px]:flex max-[900px]:gap-5 max-[650px]:gap-3 max-[750px]:flex-col">
                <Personalize />
                <div className="bg-white rounded-2xl max-[900px]:w-2/4 max-[750px]:w-full">
                  <Chart />
                </div>
              </div>
              <div className="bg-white rounded-2xl w-3/5 rightside max-[900px]:w-full">
                <PersonalizePremium />
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar onSelect={handleSelect} />
        <div className="right-baar-div transition">
          <Navbar />
          <div className="pr-7 c-padding-r bg-[#f2f3fa] max-[650px]:pr-3">
            {renderCurrentComponent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
