import { put, call, takeLatest } from "redux-saga/effects";
import {
  signupRequest,
  signupSuccess,
  signupFailure,
  loginRequest,
  loginSuccess,
  loginFailure
} from "../../reducers/ducks/signupDuck";
import { registerUserApi , loginUserApi } from "../../../api/signupApi";

function* signupSaga(action) {
  try {
    const response = yield call(registerUserApi, action.payload);
    yield put(signupSuccess(response));
  } catch (error) {
    const errorMsg = error.message || "An unexpected error occurred";
    yield put(signupFailure(errorMsg));
  }
}

function* loginSaga(action) {
  try {
    const response = yield call(loginUserApi, action.payload);
    console.log("Login API response:", response); 
    yield put(loginSuccess(response));
  } catch (error) {
    const errorMsg = error.message || "An unexpected error occurred";
    yield put(loginFailure(errorMsg));
  }
}


export function* watchSignupSagas() {
  yield takeLatest(signupRequest.type, signupSaga);
}

export function* watchLoginSagas() {
  yield takeLatest(loginRequest.type, loginSaga);
}
